<template>
  <div>
    <v-card-text>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allStates"
        v-model="state_filter"
        item-text="state_name"
        item-value="id"
        label="State*"
        return-object
        required
        :rules="[rules.required('State')]"
        @change="loadMPconstituencies(state_filter.id,'Active')"
      ></v-autocomplete>

      <v-data-table
        :headers="headers"
        :items="allMpConstituencies"
        fixed-header
        height="400px"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
        dense
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat dense>
            <v-toolbar-title>MP constituencies</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-chip class="ma-2" color="green" text-color="white">
              Total
              <v-avatar right class="green darken-4">
                {{ allMpConstituencies.length }}
              </v-avatar>
            </v-chip>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on" text color="indigo">
                  {{ selectedStatus }} <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="status in ['Active', 'Deleted']"
                  :key="status"
                  @click="changeStatusFilter(status)"
                  dense
                >
                  <v-list-item-title>{{ status }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn dark class="primary" @click="addNew">
              <v-icon left dark> mdi-plus </v-icon>
              ADD NEW
            </v-btn>
            <v-dialog v-model="dialog" persistent max-width="400">
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn dark class="primary" v-bind="attrs" v-on="on">
                  <v-icon left dark> mdi-plus </v-icon>
                  ADD NEW
                </v-btn>
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="text-h5">Add MP Consistency</span>
                </v-card-title>
                <!-- <v-toolbar dark color="primary" dense>
                  <v-btn icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Save MP Consistency</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text  @click="postData">
                      Save
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar> -->
                <v-card-text class="mt-5">
                  <div>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row dense>
                        <!-- <v-col cols="3">
                          <v-text-field
                            dense
                            v-model="mpconstituency"
                            label="MP Constituency*"
                            required
                            :rules="[rules.required('MP Constituency')]"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12">
                          <v-autocomplete
                            dense
                            :items="allStates"
                            v-model="state"
                            item-text="state_name"
                            item-value="id"
                            label="State*"
                            required
                            return-object
                            :rules="[rules.required('State')]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            dense
                            v-model="mpconstituency"
                            label="Name*"
                            required
                            :rules="[rules.required('Name')]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="postData">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.state_name="{ item }">
          {{ item.state_id ? state_filter.state_name : "" }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark small>
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import rules from "../helpers/validation";

export default {
  name: "Home",
  components: {
    // HollowDotsSpinner,
  },
  data: () => ({
    moment: moment,
    selectedStatus: "Active",
    search: "",
    tableLoading: false,
    state_filter: "",
    district_filter: "",
    dialog: false,
    valid: true,
    state: "",
    district: "",
    mandal: "",
    village: "",
    mpconstituency: "",
    rules: rules,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
      },
      {
        text: "MP Consistency",
        value: "mp_constituency_name",
      },

      {
        text: "State Name",
        value: "state_name",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "allStates",
      "alertType",
      "alertMessage",
      "allDistricts",
      "allMandals",
      "allVillages",
      "allMpConstituencies",
    ]),
    ...mapState(["response"]),

    loadingStatus() {
      return this.$store.state.loadingStatus;
    },
  },
  mounted() {
    this.$store.commit("set_MPConstituencies", []);
    this.$store.dispatch("fetchStates");
  },
  methods: {
    ...mapActions([
      "showAlert",
      "fetchStates",
      "fetchDistricts",
      "fetchMPConstituencies",
      "addNewMPConstituency",
    ]),
    async postData() {
      if (this.$refs.form.validate()) {
        const payload = {
          locations: [
            {
              name: this.mpconstituency,
            },
          ],
          state: this.state.state_name,
        };
        await this.addNewMPConstituency(payload);

        if (this.state_filter) {
          await this.loadMPconstituencies(this.state_filter.id,"Active");
        }
        this.closeDialog();
      }
    },
    addNew() {
      if (this.state_filter) {
        this.state = this.state_filter;
      }
      this.dialog = true;
    },
    async changeStatusFilter(status) {
      this.selectedStatus = status;
      if (this.state_filter) {
          await this.loadMPconstituencies(this.state_filter.id,this.selectedStatus);
        }
    },
  },
};
</script>
<style scoped>
.custom-badge {
  background-color: darksalmon;
}
</style>
